<template>
  <BreadCrumb :breadcrumbs="breadcrumbs" />
  <Alert :class="alertClass" v-show="alertContent" @closeAlert="alertContent = ''">{{alertContent}}</Alert>
  <div class="mb-1 row">
    <div class="input-group mb-3 w-50 ms-1">
      <span class="input-group-text fa fa-search" />
      <input type="text" class="form-control" placeholder="Search by Account" v-model="term" @input="filterList"/>
      <span class="input-group-text fa fa-trash" @click="term=''; filterList();" style="cursor: pointer;"></span>
    </div>
    <select v-model="size" class="form-select" style="width: 95px;padding-top: 0px;padding-bottom: 0px;height: 37px;" @change="filterList">
      <option v-for="(item, key) in pageSize" :key="key" :value="item">{{item}}</option>
    </select>
  </div>
  <form class="form-inline">

  </form>
  <div v-if="total > 0" class="mb-1 ms-2">
    <span class="me-4">Total: {{ total }}</span>
    <span class="me-4">Failed: {{ failed }}</span>
    <span>Failure rate: {{ parseFloat((100 * failed) / total).toFixed(2) }}%</span>
  </div>
  <Table v-if="filteredSummaryTableBody.length > 0" :tableBody="filteredSummaryTableBody" :tableHeader="tableSummaryHeader"></Table>
  <Table :tableBody="filteredTableBody" :tableHeader="tableHeader"></Table>
</template>

<script>
import axios from "axios";

var getStatus = function (item) {
  if (item.status === "Canceled") {
    return { type: "status", class: "badge bg-danger", name: item.status };
  } else if (item.status === "Placed") {
    return { type: "status", class: "badge bg-secondary", name: item.status };
  } else {
    return { type: "status", class: "badge bg-success", name: item.status };
  }
};

export default {
  name: "BestBuy",
  mounted() {
    this.getList();
  },
  data() {
    return {
      tableHeader: [
        "Account",
        "Order Id",
        "Order Date",
        "Ship Date",
        "Status",
      ],
      tableBody: [],
      filteredTableBody: [],
      tableSummaryHeader: [
        "Account",
        "Total",
        "Failed",
        "Failure Rate",
        "Created Date",
        "Last Order",
        "Status",
      ],
      tableSummaryBody: [],
      filteredSummaryTableBody: [],
      breadcrumbs: [{ name: "Best Buy Order" }],
      alertContent: "",
      alertClass: "alert-danger",
      total: 0,
      failed: 0,
      loading: false,
      term: this.$route.query.account || "",
      pageSize: [100, 200, 500, 1000, 5000, 10000],
      size: 200
    };
  },
  methods: {
    getList() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      axios
        .get("/bestbuy")
        .then((response) => {
          var summary = new Map();
          this.tableBody = response.data.map((item) => {
            this.total++;
            if (item.status === "Canceled") {
              this.failed++;
            }

            if (!summary.get(item.account)) {
              summary.set(item.account, {
                failed: 0,
                total: 0,
                firstOrder: item.orderDate,
              });
            }
            summary.get(item.account).total++;
            summary.get(item.account).lastOrder = item.orderDate;
            summary.get(item.account).status = summary.get(item.account).status || item.status;
            if (item.status == "Canceled") {
              summary.get(item.account).failed++;
            }

            return {
              account: {
                type: "buttonLink",
                click: () => { this.term = item.account; this.filterList() },
                name: item.account
              },
              orderId: item.orderId,
              orderDate: new Date(item.orderDate).toLocaleString(),
              updatedDate: new Date(item.actionDate) < new Date("2000-01-01") ? "" : new Date(item.actionDate).toLocaleString(),
              status: getStatus(item)
            };
          });

          summary.forEach((item, key) => {
            item.failedRate = (item.failed/item.total * 100).toFixed(2);
            item.account = key;
            if(parseFloat(item.failedRate) < 18.5 && parseFloat(item.total) > 9) {
                this.tableSummaryBody.push(item);
            }
          })

          this.tableSummaryBody = this.tableSummaryBody
              .sort((a, b) => parseFloat(a.failedRate) > parseFloat(b.failedRate) ? 1 : -1)
              .map(item => {
                  return {
                    account: {
                      type: "buttonLink",
                      click: () => { this.term = item.account; this.filterList() },
                      name: item.account
                    },
                    total: item.total,
                    failed: item.failed,
                    failureRate: item.failedRate + "%",
                    lastOrder: new Date(item.lastOrder).toLocaleString(),
                    created: (new Date(item.firstOrder).toLocaleString()),
                    status: getStatus(item)
                  }
              }
          )

          this.filterList();

          this.loading = false;
        })
        .catch((error) => {
          this.alertContent = error.response ? error.response.data : error;
          this.alertClass = "alert-danger";
          this.loading = false;
        });
    },
    filterList() {
      if (this.term && this.term.length > 3) {
        this.filteredTableBody = this.tableBody.filter((item) => item.account.name.toLowerCase().indexOf(this.term.toLowerCase()) > -1);
        this.filteredSummaryTableBody = this.tableSummaryBody.filter((item) => item.account.name.toLowerCase().indexOf(this.term.toLowerCase()) > -1);
      } else {
        this.filteredTableBody = this.tableBody.slice(0, this.size);
        this.filteredSummaryTableBody = this.tableSummaryBody;
      }
    }
  },
};
</script>